<template>
<b-row>
  <b-col cols="12" xl="5" class="nopadding">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link" id="home-tab" data-toggle="tab" v-on:click="activetab=1" v-bind:class="[ activetab === 1 ? 'show active' : '' ]"
        role="tab" aria-controls="home" aria-selected="true"><i class="fa fa-file-text-o" aria-hidden="true"></i> {{$t('common.client_list')}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" v-on:click="activetab=2" v-bind:class="[ activetab === 2 ? 'show active' : '' ]"
        role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.order_list')}}</a>
      </li>
    </ul>
    <div class="tab-content" id="tab_booking_pawn">
      <div class="tab-pane fade" v-bind:class="[ activetab === 1 ? 'show active' : '' ]" id="tab_pane_booking_pawn" role="tabpanel" aria-labelledby="booking-tab">
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter_client" maxlenght="50" @keyup.enter="apply_filter_client">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter_client"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <div class="row div-card-scroll">
          <div class="col">
            <div class="row">
              <div class="col-md-4 col-xs-3 form-fixer" v-for="itm in filteredAndSortedData_client" :key="itm.id">
                <b-card class="booking-detail"
                  @click="book_new_order(itm)"
                  :border-variant="itm.status>0?'primary':''">
                  <div class="d-flex justify-content-between align-items-center" slot="header">
                    <div class="bg-primary">
                      <i>{{itm.name}}</i>
                    </div>
                    <div class="bg-primary" v-if="itm.status>0">
                      <i>{{$t('common.using')}}</i>
                    </div>
                    <div class="bg-primary" v-else>
                      <i>{{$t('common.table_empty')}}</i>
                    </div>
                  </div>

                  <div slot="footer">
                    <div class="btn-group pull-left" v-if="itm.status>0">
                      <button type="button" class="btn btn-success btn-sm"
                      @click="client_bill_pdf(itm,57)" v-b-modal.bill_viewModal>bill 57</button>
                      <button type="button" class="btn btn-success btn-sm"
                      @click="client_bill_pdf(itm,80)" v-b-modal.bill_viewModal>bill 80</button>
                    </div>
                    <div class="btn-group pull-right" v-if="itm.status>0">
                      <button type="button" class="btn btn-success btn-sm"
                      @click="confirmReleaseObject(itm)">{{$t('common.release_client')}}</button>
                    </div>
                  </div>
                  <div>
                    <label class="row">{{$t('common.client')}}: {{itm.name}}</label>
                    <label class="row">{{$t('common.gender')}}: {{get_gender(itm.gender)}}</label>
                    <label class="row">{{$t('common.phone')}}: {{itm.phone}}</label>
                    <label class="row pull-right"><small class="text-muted">{{$t('common.updated_time',{hour:day_diff(itm.updated_at)})}}</small></label>
                  </div>
                </b-card>
              </div>
            </div>
            <div class="row">
              <div class="col form-fixer">
                <pager class="pull-right" :frame="8" size="sm"
                      :pageCount="page_count_client"
                      :page="page_client"
                      @change="change_page_client">
                </pager>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" v-bind:class="[ activetab === 2 ? 'show active' : '' ]" id="tab_pane_order_pawn" role="tabpanel" aria-labelledby="order-tab">
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter_order" maxlenght="50" @keyup.enter="apply_filter_order">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter_order"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData_order" :fields="fields" :current-page="page_order"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="company" slot-scope="data">
            <label v-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else></label>
          </template>
          <template slot="client" slot-scope="data">
            <label v-if="data.item.client">{{data.item.client.name}}</label>
            <label v-else></label>
          </template>
          <template slot="total" slot-scope="data">
            <label v-if="data.item.total>0">{{formatPrice(data.item.total)}}</label>
            <label v-else>0</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getStatusText(data.item.status)}}</b-badge>
          </template>

          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
           <template slot="bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="order_bill_pdf(data.item.id,57)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill 57</b-button>
              <b-button variant="primary" @click="order_bill_pdf(data.item.id,80)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill 80</b-button>
            </b-button-group>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="row" v-show="false">
          <b-col>
            <pager class="pull-right" :frame="8"
                  :pageCount="page_count_order"
                  :page="page_order"
                  @change="change_page_order">
            </pager>
          </b-col>
        </div>
      </div>
    </div>
  </b-col>
  <b-col cols="12" xl="7" class="nopadding">
    <b-card>
      <div slot="header">
        <div class="row">
          <div class="col-3 form-fixer">
            <label for="ddl_object_list">{{$t('common.select_room')}}</label>
            <div v-if="$v.order.object_id.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.order.object_id.required">{{$t('common.room_required')}}</div>
            </div>
            <b-form-select id="ddl_object_list"
              @change="onObjectChange(order.object_id)"
              v-model.trim="order.object_id" size="sm"
              :placeholder="$t('common.select_room')">
              <option value="null" disabled>{{$t('common.select_room')}}</option>
              <option v-for="d in ls_object_ddl" :value="d.id"
                  v-bind:key="d.id">{{d.name}} - [{{d.price}}]</option>
            </b-form-select>
          </div>
          <div class="col-2 form-fixer">
            <b-form-group>
              <label for="txt_price">{{$t('common.price')}}</label>
              <div v-if="$v.order.price.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.order.price.required">{{$t('common.price_required')}}</div>
              </div>
              <money v-model="order.price" v-bind="money"  class="form-control text-success"
              id="txt_price" maxLength="14"></money>
            </b-form-group>
          </div>
          <div class="col-2 form-fixer">
            <b-form-group>
              <label for="txt_discount">{{$t('common.discount')}}</label>
              <money v-model.trim="order.discount" v-bind="money_discount" class="form-control text-success"
                id="'txt_discount" maxLength="4"></money>
            </b-form-group>
          </div>
          <div class="col-5 text-danger h4 form-fixer">
            <strong class="pull-right"><i class="fa fa-money 2x"
            aria-hidden="true"></i> {{formatPrice(total_recal)}} {{$t('common.money_icon')}}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-3 form-fixer">
            <b-form-group>
              <label for="txt_in_time" class="control-label">{{$t('common.intime')}}</label>
              <div class="pull-right" v-if="$v.order.in_time.$error">
                <div class="text-danger" v-if="!$v.order.in_time.required">{{$t('common.time_required')}}</div>
              </div>
              <b-form-input type="time" class="form-control" v-model="order.in_time" id="txt_in_time" size="sm"
                maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4 form-fixer">
            <b-form-group>
              <label for="txt_out_time" class="control-label">{{$t('common.outtime')}}</label>
              <div class="pull-right" v-if="$v.order.out_time.$error">
                <div class="text-danger" v-if="!$v.order.out_time.required">{{$t('common.time_required')}}</div>
              </div>
              <b-form-input type="time" class="form-control" v-model="order.out_time" id="txt_out_time" size="sm"
              maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-md-6 form-fixer">
              <b-form-group>
                <label for="txt_client_name">{{$t('common.client')}}</label>
                <div v-if="$v.client.name.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.client.name.required">{{$t('common.name_required')}}</div>
                </div>
                <vue-bootstrap-typeahead size="sm"
                    v-model="client.name" id="txt_client_name" ref="txt_client_name"
                    :placeholder="$t('common.placeholder_name')"
                    v-on:input="$v.client.name.$touch"
                    :serializer="item => item.name"
                    :data="ls_client_name"
                    @hit="selectedUser = $event"/>
              </b-form-group>
            </div>
            <div class="col-md-3 form-fixer">
              <b-form-group>
                <label for="ddlGender">{{$t('common.gender')}}</label>
                <div v-if="$v.client.gender.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.client.gender.required">{{$t('common.gender_required')}}</div>
                </div>
                <b-form-select id="ddlGender" v-model.trim="client.gender"
                  v-on:input="$v.client.gender.$touch" size="sm">
                    <option value="null" disabled>{{$t('common.select_gender')}}</option>
                    <option v-for="d in ls_gender" :value="d.id"
                        v-bind:key="d.id">{{d.name[$i18n.locale]}}</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3 form-fixer">
              <b-form-group>
                <label for="validation_dob">{{$t('common.dob')}}</label>
                <date-picker v-model.trim="client.dob" :config="options1" class="date-picker-height"
                :placeholder="$t('common.placeholder_dob')" id=validation_dob></date-picker>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col form-fixer">
              <b-form-group>
                <label for="txt_client_phone">{{$t('common.phone')}}</label>
                <div v-if="$v.client.phone.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.client.phone.numeric">{{$t('common.phone_number_only')}}</div>
                  <div class="text-danger" v-else-if="!$v.client.phone.minLength">{{$t('common.phone_ten_number')}}</div>
                </div>
                <b-form-input type="text" id="txt_client_phone"
                v-model.trim="client.phone" maxlength="15" size="sm"
                v-on:input="$v.client.phone.$touch" :disabled="client.client_id!=null"
                :placeholder="$t('common.placeholder_phone')"></b-form-input>
              </b-form-group>
            </div>
            <div class="col form-fixer">
              <b-form-group>
                <label for="txtEmail">{{$t('common.email')}}</label>
                <div v-if="$v.client.email.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.client.email.email">{{$t('common.email_invalid')}}</div>
                </div>
                <b-form-input type="email" id="txtEmail"
                v-model.trim="client.email" maxlength="50" size="sm"
                v-on:input="$v.client.email.$touch" :disabled="client.client_id!=null"
                :placeholder="$t('common.placeholder_email')"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-fixer">
              <b-form-group>
              <label for="'txt_address">{{$t('common.address')}}</label>
              <textarea class="form-control" maxlength="300" id="txt_address"
              v-model.trim="client.address" :placeholder="$t('common.placeholder_address')"></textarea>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <fieldset class="scheduler-border col-md-12">
              <legend class="scheduler-border h4">{{$t('common.product_service')}}</legend>
              <div class="div-scroll row">
                <div class="col">
                  <div class="row" v-for="(itm, k) in $v.order.order_detail.$each.$iter"
                    :key="k" :class="['', (itm.status.$model>-1?(k%2==0?'bg-light':''):'bg-secondary')]">
                    <div class="form-group col-md-3 form-fixer-list">
                      <label :for="'txt_product_'+k">{{$t('common.product')}}</label>
                      <div v-if="$v.order.order_detail.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.product_id.required">{{$t('common.product_required')}}</div>
                        <div class="text-danger" v-else-if="!itm.product_id.isUnique">{{$t('common.product_existing')}}</div>
                      </div>
                      <b-form-select v-model.trim="itm.product_id.$model"
                        :id="'ddl_product_'+k" v-on:input="itm.product_id.$touch" size="sm"
                        @change="onProductChange(k,itm.product_id.$model)">
                        <option value="null" disabled>{{$t('common.select_product')}}</option>
                        <option v-for="c in ls_product_ddl" :value="c.id"
                        v-bind:key="c.id">{{c.name}} {{c.supply?" - ["+c.supply.name+"]":""}}</option>
                      </b-form-select>
                    </div>
                    <div class="form-group col-md-2 form-fixer-list">
                      <label :for="'ddl_unit_'+k">{{$t('common.unit')}}</label>
                      <div v-if="$v.order.order_detail.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.unit_id.required">{{$t('common.unit_required')}}</div>
                      </div>
                      <b-form-select :id="'ddl_unit_'+k" :disabled="itm.product_id.$model==null"
                      v-model="itm.unit_id.$model" @change="onUnitChange(k,itm.unit_id.$model)" size="sm">
                          <option value="null" disabled>{{$t('common.select_unit')}}</option>
                          <option v-for="d in order.order_detail[k].ls_unit_ddl" :value="d.id"
                              v-bind:key="d.id">{{JSON.parse(d.name)[$i18n.locale]}}</option>
                      </b-form-select>
                    </div>
                    <div class="form-group col-md-2 form-fixer-list">
                      <label :for="'txt_price_'+k">{{$t('common.price')}}</label>
                      <div v-if="$v.order.order_detail.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.price.required">{{$t('common.price_required')}}</div>
                      </div>
                      <money v-model.trim="itm.price.$model" v-bind="money" class="form-control"
                      :name="'txt_price_'+k" :id="'txt_price_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                      @change.native="onPriceChange(k,itm.price.$model)"
                      maxLength="15"></money>
                    </div>
                    <div class="form-group col-md-2 form-fixer-list">
                      <label :for="'txt_quantity_'+k">{{$t('common.quantity')}}</label>
                      <div v-if="$v.order.order_detail.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.quantity.required">{{$t('common.quantity_required')}}</div>
                        <div class="text-danger" v-else-if="!itm.quantity.numeric">{{$t('common.quantity_number_only')}}</div>
                      </div>
                      <b-form-input type="number" @change="onQualityChange(k,itm.quantity.$model)"
                        v-model.trim="itm.quantity.$model" class="form-control" size="sm"
                        :name="'txt_quantity_'+k" :id="'txt_quantity_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                        maxLength="15" :placeholder="$t('common.placeholder_quantity')"></b-form-input>
                    </div>
                    <div class="form-group col-md-2 form-fixer-list">
                      <label :for="'txt_total_'+k">{{$t('common.total')}}</label>
                      <div v-if="$v.order.order_detail.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.total.required">{{$t('common.total_required')}}</div>
                        <div class="text-danger" v-else-if="!itm.total.numeric">{{$t('common.total_number_only')}}</div>
                      </div>
                      <money v-model.trim="itm.total.$model" v-bind="money"  class="form-control"
                      :name="'txt_total_'+k" :id="'txt_total_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                      maxLength="15"></money>
                    </div>
                    <div class="col-md-1 pull-right text-white" v-if="itm.status.$model>-1">
                      <div v-if="order.id==null">
                        <a class="btn btn-danger mr-1 btn-sm" v-if="order.order_detail.length>0"
                          @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                        <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                      </div>
                      <div v-else>
                        <a class="btn btn-danger mr-1 btn-sm"
                        @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                        <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                      </div>
                    </div>
                    <div class="col-md-1 pull-right text-white" v-else>
                      <a class="btn btn-success mr-1 btn-sm" v-if="order.order_detail.length>0"
                        @click="itm.status.$model=0"><i class="fa fa-refresh" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="row">
        <b-button variant="outline-primary mr-1" @click.prevent="save_order()" size="sm"><span class="icon is-small">
          <i class="fa fa-check"></i> {{$t('common.button.save')}} </span></b-button>
        <b-button variant="outline-success" @click.prevent="clear()" size="sm"><span class="icon is-small">
          <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</span></b-button>
      </div>
    </b-card>
  </b-col>
  <b-modal ref="bill_viewModal" id="bill_viewModal" :title="$t('common.view_bill')" size="lg" header-bg-variant="info" no-body ok-only>
    <div id="pawn_order_bill_pdf" style="height:450px;"></div>
  </b-modal>
</b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }
  legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    padding:0 10px;
    border-bottom:none;
  }
  div.card.booking-detail > div.card-body{
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  div.card.booking-detail > div.card-header{
    padding: 0px !important;
    border-bottom:0px !important;
  }
  div.card.booking-detail > div.card-footer{
    padding: 0px !important;
    border-top:0px !important;
  }
</style>
<script>
  import pdfobj from 'pdfobject'
  import { validationMixin } from 'vuelidate'
  import { required,requiredIf, minLength,maxLength,email,numeric } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import datePicker from 'vue-bootstrap-datetimepicker'
  import moment from 'moment'
  import Pager from '../Pager'
  import { ModelSelect } from 'vue-search-select'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
  import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
  import { Role } from '@/helpers/role.js'
  import GroupButton from '../custom/GroupButton'

  export default {
    components: { datePicker,ModelSelect,Pager,VueBootstrapTypeahead,GroupButton},
    mixins: [validationMixin],
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        bill_id:80,
        activetab: 1,
        selectedUser: null,
        ls_client_name:[],
        today:moment().format("DD-MM-YYYY HH:mm:ss"),
        processing:false,
        ls_gender: [],
        money_discount: {
          decimal: ',',
          thousands: '.',
          prefix: '% ',
          precision: 0,
          masked: false
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$ ',
          precision: 0,
          masked: false
        },
        client:{
          id:null,
          logo:null,
          name:null,
          email:null,
          dob:null,
          gender:null,
          phone:null,
          address:null,
          des:null,
          cmnd:null,
          issued_date:null,
          issued_place:null,
          user_create_id:null,
          user_update_id:null,
          branch_id:null,
          company_id:null,
          business:null,
          code:moment().valueOf().toString()
        },
        order: {
          id:null,
          client_id:null,
          object_id:null,
          order_detail:[],
          user_create_id:null,
          user_update_id:null,
          total:0,
          price:0,
          company_id:null,
          business:null,
          branch_id:null,
          in_time:moment().format('HH:mm'),
          out_time:moment().add(1,"hour").format("HH:mm"),
          des:null,
          discount:0,
          code:moment().valueOf().toString()
        },
        options: {
          format: 'DD-MM-YYYY',
          useCurrent: true,
          showClear: true,
          showClose: true
        },
        options1: {
          format: 'DD-MM-YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true
			  }
      }
    },
    validations: {
      client: {
        dob:{
          required,
        },
        name: {
          required,
          maxLength:maxLength(40)
        },
        gender:{
          required
        },
        email:{
          email,
          maxLength:maxLength(50)
        },
        phone: {
          numeric,
          minLength: minLength(10),
          maxLength:maxLength(15)
        }
      },
      order: {
        in_time:{
          required
        },
        price:{
          required
        },
        object_id:{
          required
        },
        out_time:{
          required
        },
        order_detail: {
          $each: {
            product_id:{
              required: requiredIf(function () {
                return this.order.order_detail.length>0
              }),
              async isUnique(value,itm) {
                debugger
                if(this.order.order_detail.length<=0) return Boolean(true)
                let arr=this.order.order_detail.filter(x => (x.unit_id == itm.unit_id && x.product_id==itm.product_id))
                return Boolean(arr.length<=1)
              }
            },
            unit_id:{
              required: requiredIf(function (itm) {
                return this.order.order_detail.length>0 && itm.product_id!=null
              })
            },
            quantity:{
              required: requiredIf(function () {
                return this.order.order_detail.length>0
              }),
              numeric
            },
            total:{
              required: requiredIf(function () {
                return this.order.order_detail.length>0
              }),
              numeric
            },
            price:{
              required: requiredIf(function () {
                return this.order.order_detail.length>0
              }),
              numeric
            },
            status:{},
            ls_unit_ddl:[]
          }
        }
      }
    },
    computed: {
      ...mapGetters('st_order', ['ls_order','per_page_order','filter_order','page_count_order','page_order']),
      ...mapGetters('st_client', ['ls_client','per_page_client','filter_client','page_count_client','page_client']),
      ...mapGetters('st_object', ['ls_object_ddl']),
      fields(){
        let fields=[]

        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
        }else if(user.role==Role.Admin){
          fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true, variant:'info'})
        }

        fields.push({key: 'client',label:this.$t('common.client'),sortable: true})
        fields.push({key: 'total',label:this.$t('common.total'),sortable: true})
        fields.push({key: 'status',label:this.$t('common.status'),sortable: true})
        fields.push({key: 'bill',label:this.$t('common.bill'),sortable: false})
        fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      total_recal(){
        let temp=_.sumBy(this.order.order_detail.filter(x=>x.status>=0),"total")
        return parseInt(temp)
      },
      ls_product_ddl(){
        let ls= this.$store.state.st_product.ls_product_ddl
        return ls
      },
      is_super () {
        let user= this.$store.state.st_authentication.user
        return user.role==Role.Super_Admin
      },
      business () {
        let user= this.$store.state.st_authentication.user
        return user.company.business
      },
      user_login () {
        return this.$store.state.st_authentication.user
      },
      per_page_client:{
        get: function(){
          return this.$store.state.st_client.per_page_client
        },
        set: function(page){
          this.$store.commit('st_client/set_per_page',page)
        }
      },
      filter_order:{
        get: function(){
          return this.$store.state.st_order.filter_order
        },
        set: function(text){
          this.$store.commit('st_order/set_filter',text)
        }
      },
      filter_client:{
        get: function(){
          return this.$store.state.st_client.filter_client
        },
        set: function(text){
          this.$store.commit('st_client/set_filter',text)
        }
      },
      ls_unit_ddl(){
        return this.$store.state.st_unit.ls_unit_ddl
      },

      ls_client_ddl(){
        return this.$store.state.st_client.ls_client_ddl
      },

      ls_object_ddl(){
        debugger
        return this.$store.state.st_object.ls_object_ddl
      },

      filteredAndSortedData_order:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_order.ls_order
          if (vm.filter_order) {
            result = result.filter(item =>vm.searchLike(item))
          }

          return result
        }
      },
      filteredAndSortedData_client:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_client.ls_client
          if (vm.filter_client) {
            result = result.filter(item =>vm.searchLike_client(item))
          }

          return result
        }
      }
    },
    methods: {
      ...mapActions('st_order', ['get_ls_order','apply_filter_order','change_page_order']),
      ...mapActions('st_client', ['get_ls_client','apply_filter_client','change_page_client']),
      ...mapActions('st_object', ['get_ls_object_ddl']),
      ...mapActions('st_unit', ['get_ls_unit_ddl']),
      ...mapActions('st_product', ['get_ls_product_ddl']),

      day_diff(str_date_time){
        let now=moment()
        let another_date=moment(str_date_time, 'YYYY-MM-DD hh:mm:ss')
        let diff=now.diff(another_date, 'minutes')
        if(diff<60){
          return diff +" "+ this.$t('common.minutes') +" "
        }else if(diff<(60*24)){
          return now.diff(another_date, 'hours') +" "+ this.$t('common.hours')+" "
        }else if(diff<(60*24*7)){
          return now.diff(another_date, 'days') +" "+ this.$t('common.days')+" "
        }else{
          return now.diff(another_date, 'weeks') +" "+ this.$t('common.weeks')+" "
        }
      },
      rowDeleted(item, type){
        if (!item || type !== 'row') return
        if (item.status === -1) return 'table-danger'
      },
      async book_new_order(obj){
        debugger
        if(!obj) return
        let selected_order=this.filteredAndSortedData_order.find(x=>x.client_id==obj.id && x.company_id==obj.company_id&&x.branch_id==obj.branch_id)
        if(obj.status>0 && selected_order){
          this.order=selected_order

          for (var i = 0; i < selected_order.order_detail.length; i++) {
            let element = selected_order.order_detail[i]
            let product=this.ls_product_ddl.find(x=>x.id==element.product_id)
            if(product){
              var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
                return product.product_units.filter(function(anotherOne_el){
                  if(anotherOne_el.unit_id == array_el.id){
                    array_el.buy_price= anotherOne_el.buy_price
                    array_el.sell_price= anotherOne_el.sell_price
                  }
                  return anotherOne_el.unit_id == array_el.id
                }).length > 0
              })

              if(filteredArray.length<=0){
                filteredArray=this.ls_product_ddl
              }

              element.ls_unit_ddl=filteredArray
            }
          }
        }else{
          this.clear()
        }

        this.$refs.txt_client_name.inputValue = obj.name
        this.client=obj
      },
      client_bill_pdf(obj){
        let app=this
        debugger
        let order=this.filteredAndSortedData_order.find(x=>x.client_id==obj.id&&x.status==1)
        if(!order) return
        app.$refs.bill_viewModal.show()
        app.order_bill_pdf(order.id,app.bill_id)
      },
      order_bill_pdf(id,type){
        let app=this
        var loader = app.$loading.show()
        app.$store.dispatch('st_order/bill_pdf',{id,type,business:app.business,lang:app.$i18n.locale}).then((response) => {
          if(response && response.data){
            let blob = new Blob([response.data], { type: 'application/pdf' })
            pdfobj.embed(window.URL.createObjectURL(blob), "#pawn_order_bill_pdf")
          }

          loader.hide()
        })
      },
      onObjectChange(id){
        debugger
        let obj=this.ls_object_ddl.find(x=>x.id==id)
        if(obj){
          this.order.price=obj.price
        }
      },
      onProductChange(k,id){
        if(id==null) return
        debugger
        let product=this.ls_product_ddl.find(x => x.id === id)
        k=parseInt(k)
        if(product){
          var filteredArray = this.ls_unit_ddl.filter(function(array_el){
            return product.product_units.filter(function(anotherOne_el){
              if(anotherOne_el.unit_id == array_el.id){
                array_el.buy_price= anotherOne_el.buy_price
                array_el.sell_price= anotherOne_el.sell_price
              }
              return anotherOne_el.unit_id == array_el.id
            }).length > 0
          })

          if(filteredArray.length<=0){
            filteredArray=this.ls_product_ddl
          }

          this.order.order_detail[k].ls_unit_ddl=filteredArray
          this.order.order_detail[k].price=product.sell_price
        }else{
          this.order.order_detail[k].price=0
        }

        this.order.order_detail[k].quantity=0
        this.order.order_detail[k].total=0
        this.order.order_detail[k].unit_id=null
      },
      onUnitChange(k,unit_id){
        if(unit_id==null) return
        debugger
        k=parseInt(k)
        let unit=this.order.order_detail[k].ls_unit_ddl.find(x=>x.id==unit_id)
        this.order.order_detail[k].quantity=1
        if(unit){
          this.order.order_detail[k].total=this.order.order_detail[k].price=unit.sell_price
        }
      },
      onObjectChange(id){
        debugger
        let obj=this.ls_object_ddl.find(x=>x.id==id)
        if(obj){
          this.order.price=obj.price
        }
      },
      onPriceChange(k,event){
        if(event==null) return
        k=parseInt(k)
        if(this.order&&this.order.order_detail[k].quantity>0){
          this.order.order_detail[k].total=parseInt(this.order.order_detail[k].quantity) * parseInt(this.order.order_detail[k].price)
        }
      },
      onQualityChange(k,event){
        if(event==null) return
        k=parseInt(k)
        if(this.order.order_detail[k].quantity>0){
          this.order.order_detail[k].total=parseInt(this.order.order_detail[k].quantity) * parseInt(this.order.order_detail[k].price)
        }else{
          this.order.order_detail[k].total=0
        }
      },
      addNewRow() {
        this.order.order_detail.push({
          id:null,
          product_id:null,
          price:0,
          status:0,
          total:0,
          ls_unit_ddl:[],
          unit_id:null,
          quantity:0,
          user_create_id:this.user_login.id,
          user_update_id:null,
          company_id:this.user_login.company_id,
          branch_id:this.user_login.branch_id,
          business:this.user_login.company.business
        })
      },
      removeRow(k){
        if(k==null || parseInt(k)<0) return
        let itm=this.order.order_detail[k]
        if(itm && itm.id){
          this.confirmRemoveDetail(k)
        }else{
          this.order.order_detail.splice(k, 1)
        }
      },

      confirmRemoveDetail(k) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      }).then(value => {
          if(value!=true) return
          this.order.order_detail[k].status=-1
        })
        .catch(err => {
          this.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      getRowCount (items) {
        return items.length
      },

      rowClicked (item) {
        if(item==null || item.status<0) return
        this.order=item
        debugger
        this.$refs.txt_client_name.inputValue = item.client.name
        this.selectedUser=item.client.name
        this.client.name=item.client.name
        this.client.gender=item.client.gender
        this.client.dob=item.client.dob
        this.client.cmnd=item.client.cmnd
        this.client.issued_date=item.client.issued_date
        this.client.issued_place=item.client.issued_place
        this.client.email=item.client.email
        this.client.phone=item.client.phone
        this.client.client_id=item.client.id
        this.client.address=item.client.address

        for (var i = 0; i < item.order_detail.length; i++) {
          let element = item.order_detail[i]
          let product=this.ls_product_ddl.find(x=>x.id==element.product_id)
          if(product){
            var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
              return product.product_units.filter(function(anotherOne_el){
                if(anotherOne_el.unit_id == array_el.id){
                  array_el.buy_price= anotherOne_el.buy_price
                  array_el.sell_price= anotherOne_el.sell_price
                }
                return anotherOne_el.unit_id == array_el.id
              }).length > 0
            })

            if(filteredArray.length<=0){
              filteredArray=this.ls_product_ddl
            }

            element.ls_unit_ddl=filteredArray
          }
        }
      },

      clear() {
        debugger
        this.ls_client_name=[]
        this.$refs.txt_client_name.inputValue = ''
        this.order={id:null,branch_id:null,order_detail:[],
        des:null,in_time:moment().format("HH:mm"),out_time:moment().add(1,"hour").format("HH:mm"),
        company_id:null,object_id:null,user_create_id:this.user_login.id,
        discount:this.user_login.company.discount,price:0,
        user_update_id:null,business:this.user_login.company.business,code:moment().valueOf().toString(),total:0}

        this.client={id:null,gender:null,name:null,cmnd:null,address:null,issued_date:null,
        issued_place:null,dob:null,business:this.user_login.company.business,
        company_id:this.user_login.company_id,branch_id:this.user_login.branch_id,
        user_create_id:this.user_login.id,
        user_update_id:null,
        code:moment().valueOf().toString()}

        this.addNewRow()
        this.$v.$reset()
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },
      async isValidOrder () {
        debugger
        this.$v.order.$reset()
        this.$v.client.$reset()
        this.$v.order.$touch()
        this.$v.client.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.order.$error && !this.$v.client.$error)
      },

      async save_order() {
        var app = this
        const isValid = await this.isValidOrder()
        if (!isValid) return
        if (app.processing === true) return

        app.processing = true

        if(app.order.id){
          app.order.user_update_id=app.user_login.id
          app.order.total=app.total_recal
          app.$store.dispatch('st_order/update',{order:app.order,client:null,business:app.business})
          .then(function (resp) {
            app.get_ls_order()
            app.get_ls_client()
            app.clear()
            app.$toastr.success(app.$t('common.updated_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }else{
          app.order.user_create_id=app.user_login.id
          app.order.business=app.user_login.company.business
          app.order.company_id=app.user_login.company_id
          app.order.branch_id=app.user_login.branch_id
          app.order.total=app.total_recal

          app.$store.dispatch('st_order/create',{order:app.order,client:null,business:app.business})
          .then(function (resp) {
            app.get_ls_order()
            app.get_ls_client()
            app.clear()
            app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }
      },

      async recoverObj(obj){
        let app=this
        if(obj.status>=0) return
        let co=await app.$store.dispatch('st_order/recover_obj',obj.id)
        if(co && co.data.ok){
          app.get_ls_order()
          app.get_ls_client()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      confirmDelete(obj) {
        let app=this
        if(obj.status<0) return
        if(obj.status==0) {
          app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.order")}),app.$t("common.message"))
          return
        }
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      }).then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      async deleteObj(obj) {
        let app=this
        let co=await app.$store.dispatch('st_order/delete_obj',obj.id)
        if(co&&co.data.ok){
          app.get_ls_order()
          app.get_ls_client()
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      searchLike_client:function(item){
        return item.created_at.includes(this.filter_client)
        || item.updated_at&&item.updated_at.includes(this.filter_client)
        || item.company&&item.company.name.includes(this.filter_client)
        || item.name.includes(this.filter_client)
      },

      searchLike:function(item){
        return item.created_at.includes(this.filter)
        || item.updated_at&&item.updated_at.includes(this.filter)
        || item.company&&item.company.name.includes(this.filter)
        || item.des&&item.des.includes(this.filter)
      },
      async getClientName(name) {
        if(name=='' || name==null || name==undefined) return
        let res=await this.$store.dispatch('st_order/get_ls_client_name_suggest',
        {company_id:this.user_login.company_id,branch_id:this.user_login.branch_id,name:name})
        debugger
        if(res && res.data && res.data.length>0){
          this.ls_client_name =res.data
        }else{
          this.client.name=name
        }
      },
      release_object(id){
        let app=this
        app.$store.dispatch('st_order/release_object',{order_id:id,user_update_id:this.user_login.id,business:app.user_login.company.business})
        .then(function (resp) {
          app.get_ls_order()
          app.get_ls_client()
          app.clear()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
          app.processing = false
        }).catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      },
      confirmReleaseObject(obj) {
        let order=this.filteredAndSortedData_order.find(x=>x.client_id==obj.id&&x.status==1)
        if(!order) return
        this.$bvModal.msgBoxConfirm('Bạn muốn hoàn tất khách [' +obj.name+'] ?', {
          title: 'Xác nhận',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Ok',
          cancelTitle: 'Không',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if(value!=true) return
          this.release_object(order.id)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
    },
    mounted () {
      let vm =this
      vm.bill_id=vm.user_login.company.default_bill
      vm.per_page_client=12
      vm.order.code=moment().valueOf().toString()
      vm.order.discount=vm.user_login.company.discount
      vm.ls_gender=vm.option_gender
      vm.addNewRow()
      vm.get_ls_object_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
      vm.get_ls_order()
      vm.get_ls_client()
      
      vm.get_ls_product_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id,business:vm.user_login.company.business,supply_id:null})
      vm.get_ls_unit_ddl({company_id:vm.user_login.company_id,business:vm.user_login.company.business,lang:vm.$i18n.locale})
    },
    watch: {
      'client.name': _.debounce(function(name) { this.getClientName(name) }, 500),
      'selectedUser':function(newVal, oldVal){
        debugger
        if(newVal&& newVal.id>0){
          this.client.id=newVal.id
          this.client.name=newVal.name
          this.client.phone=newVal.phone
          this.client.email=newVal.email
          this.client.cmnd=newVal.cmnd
          this.client.address=newVal.address
          this.client.gender=newVal.gender
          this.client.dob=newVal.dob
          this.client.issued_date=newVal.issued_date
          this.client.issued_place=newVal.issued_place
        }
      }
    }
  }
</script>
